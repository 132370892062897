import Immutable from "immutable";
import reduce from "lodash/reduce";

import {
  API_FETCH_SUCCESS,
  API_CREATE_SUCCESS,
  API_UPDATE_SUCCESS,
  API_DESTROY_SUCCESS,
  DELETE_SUBMISSION_SUCCESS,
} from "../constants/ActionTypes";

import EntitiesStateRecord from "../records/EntitiesStateRecord";

export default function entitiesReducer(
  entities = new EntitiesStateRecord(),
  action
) {
  switch (action.type) {
    case API_FETCH_SUCCESS:
    case API_CREATE_SUCCESS:
    case API_UPDATE_SUCCESS:
      if (!action.entities) {
        return entities;
      }

      return reduce(
        action.entities,
        (oldEntitiesGroup, newEntities, entitiesType) =>
          oldEntitiesGroup.update(
            entitiesType,
            new Immutable.Map(),
            (oldEntities) => oldEntities.merge(Immutable.fromJS(newEntities))
          ),
        entities
      );

    case DELETE_SUBMISSION_SUCCESS: {
      const { formId, submissionId } = action;
      return entities
        .deleteIn(["forms", formId, "submissions", submissionId])
        .deleteIn(["submissions", submissionId]);
    }

    case API_DESTROY_SUCCESS: {
      const { entity } = action;
      if (entity) {
        return entities.deleteIn([entity.type, entity.id]);
      }
      return entities;
    }

    default:
      return entities;
  }
}
