import Immutable from "immutable";
import identity from "lodash/identity";
import store from "../store";

function getKeySeq(collection) {
  return collection
    .toSeq()
    .map((v, k) => k)
    .toIndexedSeq();
}

export function getEntities(entityType, rawEntitiesId = new Immutable.List()) {
  const entitiesId = Immutable.isCollection(rawEntitiesId)
    ? rawEntitiesId
    : new Immutable.List(rawEntitiesId);

  const entities = store.getState().entities;
  return entitiesId
    .map((id) => entities.getIn([entityType, id]))
    .filter(identity);
}

export function getEntity(entityType, entityId) {
  const entities = store.getState().entities;
  return entities.getIn([entityType, entityId]);
}

export function presentEntity(entity, presentClass) {
  if (!entity) {
    return null;
  }
  return new presentClass(entity);
}

export function presentEntities(entities = new Immutable.List(), presentClass) {
  return entities
    .filter(identity)
    .map((entity) => presentEntity(entity, presentClass))
    .filter(identity);
}

export function diff(defaults, record) {
  return getKeySeq(record).reduce((memo, key) => {
    if (!Immutable.is(defaults.get(key), record.get(key))) {
      return memo.set(key, record.get(key));
    }
    return memo;
  }, new Immutable.Map());
}
