import {
  GET_FORMS,
  GET_FORMS_SUCCESS,
  GET_FORMS_FAILURE,
  GET_FORM,
  GET_FORM_SUCCESS,
  GET_FORM_FAILURE,
  UPDATE_FORM,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_FAILURE,
  CREATE_FORM,
  CREATE_FORM_SUCCESS,
  CREATE_FORM_FAILURE,
  DELETE_FORM,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_FAILURE,
} from "../constants/ActionTypes";

import { fetch, update, create, destroy } from "./APIActions";

const getFormsAction = () => ({ type: GET_FORMS });
const getFormsSuccessAction = ({ forms }) => ({
  type: GET_FORMS_SUCCESS,
  forms,
});
const getFormsFailureAction = ({ error }) => ({
  type: GET_FORMS_FAILURE,
  error,
});

export function getForms() {
  return (dispatch) => {
    dispatch(getFormsAction());

    return dispatch(fetch("/forms"))
      .then(({ forms }) => dispatch(getFormsSuccessAction({ forms })))
      .catch((error) => dispatch(getFormsFailureAction({ error })));
  };
}

const getFormAction = ({ formId }) => ({ type: GET_FORM, formId });
const getFormSuccessAction = ({ forms }) => ({ type: GET_FORM_SUCCESS, forms });
const getFormFailureAction = ({ error }) => ({ type: GET_FORM_FAILURE, error });

export function getForm({ formId }) {
  return (dispatch) => {
    dispatch(getFormAction({ formId }));

    return dispatch(fetch(`/forms/${formId}`))
      .then(({ forms }) => dispatch(getFormSuccessAction({ forms })))
      .catch((error) => dispatch(getFormFailureAction({ error })));
  };
}

// UPDATE

const updateFormAction = ({ formId }) => ({ type: UPDATE_FORM, formId });
const updateFormSuccessAction = ({ forms }) => ({
  type: UPDATE_FORM_SUCCESS,
  forms,
});
const updateFormFailureAction = ({ error }) => ({
  type: UPDATE_FORM_FAILURE,
  error,
});

export function updateForm({ formId, form }) {
  return (dispatch) => {
    dispatch(updateFormAction({ formId }));

    return dispatch(update(`/forms/${formId}`, { form }))
      .then(({ forms }) => dispatch(updateFormSuccessAction({ forms })))
      .catch((error) => {
        dispatch(updateFormFailureAction({ error }));
        throw error;
      });
  };
}

// CREATE

const createFormAction = ({ form }) => ({ type: CREATE_FORM, form });
const createFormSuccessAction = ({ forms }) => ({
  type: CREATE_FORM_SUCCESS,
  forms,
});
const createFormFailureAction = ({ error }) => ({
  type: CREATE_FORM_FAILURE,
  error,
});

export function createForm({ form }) {
  return (dispatch) => {
    dispatch(createFormAction({ form }));

    return dispatch(create(`/forms`, { form }))
      .then(({ forms }) => dispatch(createFormSuccessAction({ forms })))
      .catch((error) => {
        dispatch(createFormFailureAction({ error }));
        throw error;
      });
  };
}

// DELETE

const deleteFormAction = ({ formId }) => ({ type: DELETE_FORM, formId });
const deleteFormSuccessAction = ({ formId }) => ({
  type: DELETE_FORM_SUCCESS,
  formId,
});
const deleteFormFailureAction = ({ error }) => ({
  type: DELETE_FORM_FAILURE,
  error,
});

export function deleteForm({ formId }) {
  return (dispatch) => {
    dispatch(deleteFormAction({ formId }));
    const entity = {
      type: "forms",
      id: formId,
    };

    return dispatch(destroy(`/forms/${formId}`, { entity }))
      .then(() => dispatch(deleteFormSuccessAction({ formId })))
      .catch((error) => {
        dispatch(deleteFormFailureAction({ error }));
        throw error;
      });
  };
}
