export function formatMoney(amountInCents: number) {
  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "narrowSymbol",
  }).format(amountInCents / 100);
}

export function formatNumber(number: number, decimals = 0) {
  return new Intl.NumberFormat(navigator.language, {
    style: "decimal",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

const SIZE_UNITS = ["B", "KB", "MB", "GB"];
export function formatSize(size: number, decimals = 0) {
  if (size === 0) return "0 B";
  const exponent = Math.min(Math.floor(Math.log(size) / Math.log(1024)), 2);
  const sizeInUnit = size / Math.pow(1024, exponent);
  return `${formatNumber(sizeInUnit, decimals)} ${SIZE_UNITS[exponent] ?? ""}`;
}
