import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

const propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

const ActiveLink = ({ to, children, className }) => (
  <NavLink
    to={to}
    className={({ isActive }) => classNames(className, { active: isActive })}
  >
    {children}
  </NavLink>
);
ActiveLink.propTypes = propTypes;

export default ActiveLink;
