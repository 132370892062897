import { Record } from "immutable";

const defaultAttributes = {
  type: "String",
  id: null,
  key: null,
  value: null,
  url: null,
  contentType: null,
  size: 0,
};

class FieldRecord extends (new Record(defaultAttributes)) {
  getValue() {
    switch (this.type) {
      case "Array":
      case "Hash":
        return this.value.map((nestedField) => new FieldRecord(nestedField));
      default:
        return this.value;
    }
  }
}
export default FieldRecord;
