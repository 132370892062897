import BaseError from "./BaseError";

class ServerError extends BaseError {
  constructor(attributes) {
    super({
      ...attributes,
      recoverable: false,
    });
  }
}

export default ServerError;
