import { Record } from "immutable";
import uniqueId from "lodash/uniqueId";

const defaultAttributes = {
  id: null,
  error: null,
  message: "",
  recoverable: true,
};

class BaseError extends (new Record(defaultAttributes)) {
  constructor(attributes = {}) {
    super({
      ...attributes,
      id: uniqueId("Error_"),
    });
  }
}

export default BaseError;
