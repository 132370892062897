import Immutable, { Record } from "immutable";
import FileRecord from "./FileRecord";
import FormRecord from "./FormRecord";
import PackRecord from "./PackRecord";
import PurchaseRecord from "./PurchaseRecord";
import SubmissionRecord from "./SubmissionRecord";
import type UserRecord from "./UserRecord";

export default Record({
  users: Immutable.Map<string, UserRecord>(),
  purchases: Immutable.Map<string, PurchaseRecord>(),
  forms: Immutable.Map<string, FormRecord>(),
  submissions: Immutable.Map<string, SubmissionRecord>(),
  files: Immutable.Map<string, FileRecord>(),
  packs: Immutable.Map<string, PackRecord>(),
});
