import Immutable from "immutable";

import {
  INIT_SUCCESS,
  CLEAR_ERRORS,
  SIGN_IN_SUCCESS,
  SIGN_UP_SUCCESS,
  SIGN_OUT_SUCCESS,
  API_FETCH_FAILURE,
  API_CREATE_FAILURE,
  API_DESTROY_FAILURE,
  GET_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_FORM,
  UPDATE_FORM_SUCCESS,
  UPDATE_FORM_CLOSED,
  UPDATE_FORM_FAILURE,
  CREATE_FORM,
  CREATE_FORM_SUCCESS,
  CREATE_FORM_FAILURE,
  DELETE_FORM,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_FAILURE,
} from "../constants/ActionTypes";

import ApplicationStateRecord from "../records/ApplicationStateRecord";
import ServerError from "../records/errors/ServerError";
import RequestError from "../records/errors/RequestError";

export default function applicationReducer(
  application = new ApplicationStateRecord(),
  action
) {
  switch (action.type) {
    case INIT_SUCCESS:
      return application.set("initialized", true);

    case GET_USER_SUCCESS:
    case SIGN_IN_SUCCESS:
    case SIGN_UP_SUCCESS:
      return application.set("user", action.users[0]);

    case SIGN_OUT_SUCCESS:
      return application.set("user", null);

    case UPDATE_USER:
      return application
        .setIn(["settingsState", "submitting"], true)
        .setIn(["settingsState", "errors"], null);

    case UPDATE_USER_SUCCESS:
      return application
        .setIn(["settingsState", "submitting"], false)
        .setIn(["settingsState", "errors"], null);

    case UPDATE_USER_FAILURE:
      if (action.error.response) {
        return application
          .setIn(["settingsState", "submitting"], false)
          .setIn(
            ["settingsState", "errors"],
            Immutable.fromJS(action.error.response.data.errors)
          );
      }
      return application.setIn(["settingsState", "submitting"], false);

    case UPDATE_FORM:
    case CREATE_FORM:
    case DELETE_FORM:
      return application
        .setIn(["formState", "submitting"], true)
        .setIn(["formState", "errors"], null);

    case UPDATE_FORM_SUCCESS:
    case UPDATE_FORM_CLOSED:
    case CREATE_FORM_SUCCESS:
    case DELETE_FORM_SUCCESS:
      return application
        .setIn(["formState", "submitting"], false)
        .setIn(["formState", "errors"], null);

    case UPDATE_FORM_FAILURE:
    case CREATE_FORM_FAILURE:
    case DELETE_FORM_FAILURE:
      if (action.error.response) {
        return application
          .setIn(["formState", "submitting"], false)
          .setIn(
            ["formState", "errors"],
            Immutable.fromJS(action.error.response.data.errors)
          );
      }
      return application.setIn(["formState", "submitting"], false);

    case API_FETCH_FAILURE:
    case API_CREATE_FAILURE:
    case API_DESTROY_FAILURE: {
      const { error } = action;

      if (error.response) {
        const { status } = error.response;
        if (status >= 500) {
          return application.set("error", new ServerError({ error }));
        } else if (status === 401) {
          return application.set("user", null);
        } else {
          return application.set("error", new RequestError({ error }));
        }
      } else {
        console.error(error);
        return application.set("error", new ServerError({ error }));
      }
    }

    case CLEAR_ERRORS:
      return application.set("error", null);

    default:
      return application;
  }
}
