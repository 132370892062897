import React, { useRef } from "react";
import { AriaMenuProps } from "@react-types/menu";
import { useTreeState } from "react-stately";
import { useMenu } from "react-aria";
import { MenuSection } from "./MenuSection";

import "./style.css";

type MenuProps<T extends object> = AriaMenuProps<T> & {
  onClose: () => void;
};

export function Menu<T extends object>(props: MenuProps<T>) {
  // Create state based on the incoming props
  let state = useTreeState(props);

  // Get props for the menu element
  let ref = useRef(null);
  let { menuProps } = useMenu(props, state, ref);

  return (
    <ul {...menuProps} ref={ref} className="menu">
      {[...state.collection].map((item) => (
        <MenuSection
          key={item.key}
          section={item}
          state={state}
          onAction={props.onAction}
          onClose={props.onClose}
        />
      ))}
    </ul>
  );
}
