import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import PushContainer from "../../containers/PushContainer";
import SidebarContainer from "../../containers/SidebarContainer";
import UserRecord from "../../records/UserRecord";
import { Banner } from "../utilities/Banner";

const FormsContainer = React.lazy(
  () =>
    import("../../containers/FormsContainer") as Promise<{
      default: React.ComponentType<any>;
    }>
);
const SettingsContainer = React.lazy(
  () =>
    import("../../containers/SettingsContainer") as Promise<{
      default: React.ComponentType<any>;
    }>
);
const PurchasesContainer = React.lazy(
  () =>
    import("../../containers/PurchasesContainer") as Promise<{
      default: React.ComponentType<any>;
    }>
);

export function AppContent({
  user,
  getForms,
}: {
  user: UserRecord;
  getForms: () => void;
}) {
  React.useEffect(() => {
    getForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-content">
      <PushContainer />
      <div className="sidebar">
        <SidebarContainer />
      </div>
      {!user?.confirmed && (
        <Banner type="info">
          <p>
            <b>You have not confirmed your email yet</b>
          </p>
          <p>
            Clicking on the confirmation email link will allow you to receive
            submissions directly into your mailbox, as well as a 50 credits free
            welcome pack!
          </p>
        </Banner>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/forms/*" element={<FormsContainer />} />
          <Route path="/user/settings/*" element={<SettingsContainer />} />
          <Route path="/user/credits/*" element={<PurchasesContainer />} />
          <Route path="*" element={<Navigate to="/forms" />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default AppContent;
