export const INIT = "INIT";
export const INIT_SUCCESS = "INIT_SUCCESS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const API_FETCH = "API_FETCH";
export const API_FETCH_SUCCESS = "API_FETCH_SUCCESS";
export const API_FETCH_FAILURE = "API_FETCH_FAILURE";
export const API_CREATE = "API_CREATE";
export const API_CREATE_SUCCESS = "API_CREATE_SUCCESS";
export const API_CREATE_FAILURE = "API_CREATE_FAILURE";
export const API_UPDATE = "API_UPDATE";
export const API_UPDATE_SUCCESS = "API_UPDATE_SUCCESS";
export const API_UPDATE_FAILURE = "API_UPDATE_FAILURE";
export const API_DESTROY = "API_DESTROY";
export const API_DESTROY_SUCCESS = "API_DESTROY_SUCCESS";
export const API_DESTROY_FAILURE = "API_DESTROY_FAILURE";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const GET_FORMS = "GET_FORMS";
export const GET_FORMS_SUCCESS = "GET_FORMS_SUCCESS";
export const GET_FORMS_FAILURE = "GET_FORMS_FAILURE";
export const GET_FORM = "GET_FORM";
export const GET_FORM_SUCCESS = "GET_FORM_SUCCESS";
export const GET_FORM_FAILURE = "GET_FORM_FAILURE";
export const UPDATE_FORM = "UPDATE_FORM";
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS";
export const UPDATE_FORM_CLOSED = "UPDATE_FORM_CLOSED";
export const UPDATE_FORM_FAILURE = "UPDATE_FORM_FAILURE";
export const CREATE_FORM = "CREATE_FORM";
export const CREATE_FORM_SUCCESS = "CREATE_FORM_SUCCESS";
export const CREATE_FORM_FAILURE = "CREATE_FORM_FAILURE";
export const DELETE_FORM = "DELETE_FORM";
export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS";
export const DELETE_FORM_FAILURE = "DELETE_FORM_FAILURE";

export const MARK_SUBMISSION_AS_READ = "MARK_SUBMISSION_AS_READ";
export const MARK_SUBMISSION_AS_READ_SUCCESS =
  "MARK_SUBMISSION_AS_READ_SUCCESS";
export const MARK_SUBMISSION_AS_READ_FAILURE =
  "MARK_SUBMISSION_AS_READ_FAILURE";
export const MARK_SUBMISSION_AS_UNREAD = "MARK_SUBMISSION_AS_UNREAD";
export const MARK_SUBMISSION_AS_UNREAD_SUCCESS =
  "MARK_SUBMISSION_AS_UNREAD_SUCCESS";
export const MARK_SUBMISSION_AS_UNREAD_FAILURE =
  "MARK_SUBMISSION_AS_UNREAD_FAILURE";
export const DELETE_SUBMISSION = "DELETE_SUBMISSION";
export const DELETE_SUBMISSION_SUCCESS = "DELETE_SUBMISSION_SUCCESS";
export const DELETE_SUBMISSION_FAILURE = "DELETE_SUBMISSION_FAILURE";

export const GET_PACKS = "GET_PACKS";
export const GET_PACKS_SUCCESS = "GET_PACKS_SUCCESS";
export const GET_PACKS_FAILURE = "GET_PACKS_FAILURE";
