import React, { Component } from "react";
import PropTypes from "prop-types";
import FormRecord from "../../../records/FormRecord";

import { buildFormURL } from "../../../utils/routes";
import Text from "../../utilities/Text";
import ActiveLink from "../../utilities/ActiveLink";

import "./style.css";

class FormListItem extends Component {
  static propTypes = {
    form: PropTypes.instanceOf(FormRecord).isRequired,
  };

  render() {
    const { id, title, unreadCount } = this.props.form;

    return (
      <ActiveLink to={buildFormURL({ formId: id })} className="form-list-item">
        <Text className="form-list-item_title" overflow>
          {title}
        </Text>
        {unreadCount > 0 && (
          <span className="form-list-item_unread-count">{unreadCount}</span>
        )}
      </ActiveLink>
    );
  }
}

export default FormListItem;
