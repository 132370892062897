import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import replaceStringToArray from "string-replace-to-array";

import "./style.css";

const EMOJI_RANGES = [
  `[\u{1F300}-\u{1F3FF}]`,
  `[\u{1F400}-\u{1F64F}]`,
  `[\u{1F680}-\u{1F6FF}]`,
].join("|");

const EMOJI_REGEX = new RegExp(
  `(?:${EMOJI_RANGES})(?:\u200D(?:${EMOJI_RANGES}))*`,
  "gu"
);

const propTypes = {
  component: PropTypes.string,
  overflow: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.string,
};

const defaultProps = {
  component: "span",
};

function wrapEmoji(text) {
  let index = 0;
  return replaceStringToArray(text, EMOJI_REGEX, (emoji) => (
    <span key={index++} className="text_emoji">
      {emoji}
    </span>
  ));
}

function Text({ component: Component, overflow, className, children }) {
  const textClassName = classNames("text", className, {
    "text--overflow": overflow,
  });

  return (
    <Component title={children} className={textClassName}>
      {wrapEmoji(children)}
    </Component>
  );
}
Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
