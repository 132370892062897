import axios from "axios";
import { normalizeResponse, normalizeRequest } from "../utils/normalizer";

import {
  API_FETCH,
  API_FETCH_SUCCESS,
  API_FETCH_FAILURE,
  API_CREATE,
  API_CREATE_SUCCESS,
  API_CREATE_FAILURE,
  API_UPDATE,
  API_UPDATE_SUCCESS,
  API_UPDATE_FAILURE,
  API_DESTROY,
  API_DESTROY_SUCCESS,
  API_DESTROY_FAILURE,
} from "../constants/ActionTypes";
import { API_URL } from "../constants/Api";

const axiosInstance = axios.create({
  baseURL: API_URL,
  responseType: "json",
  withCredentials: true,
  transformResponse: normalizeResponse,
  transformRequest: [normalizeRequest, ...axios.defaults.transformRequest],
});

export const apiFetcher = axios.create({
  baseURL: API_URL,
  responseType: "json",
  withCredentials: true,
  transformRequest: [normalizeRequest, ...axios.defaults.transformRequest],
});

const apiFetch = ({ url }) => ({ type: API_FETCH, url });
const apiFetchSuccess = ({ entities }) => ({
  type: API_FETCH_SUCCESS,
  entities,
});
const apiFetchFailure = ({ error }) => ({ type: API_FETCH_FAILURE, error });
const apiCreate = ({ url }) => ({ type: API_CREATE, url });
const apiCreateSuccess = ({ entities }) => ({
  type: API_CREATE_SUCCESS,
  entities,
});
const apiCreateFailure = ({ error }) => ({ type: API_CREATE_FAILURE, error });
const apiUpdate = ({ url }) => ({ type: API_UPDATE, url });
const apiUpdateSuccess = ({ entities }) => ({
  type: API_UPDATE_SUCCESS,
  entities,
});
const apiUpdateFailure = ({ error }) => ({ type: API_UPDATE_FAILURE, error });
const apiDestroy = ({ url }) => ({ type: API_DESTROY, url });
const apiDestroySuccess = ({ entity }) => ({
  type: API_DESTROY_SUCCESS,
  entity,
});
const apiDestroyFailure = ({ error }) => ({ type: API_DESTROY_FAILURE, error });

export function fetch(url) {
  return (dispatch, getState) => {
    dispatch(apiFetch({ url }));

    return axiosInstance
      .get(url)
      .then(({ data: { entities, results } }) => {
        dispatch(apiFetchSuccess({ entities }));
        return results;
      })
      .catch((error) => {
        dispatch(apiFetchFailure({ error }));
        throw error;
      });
  };
}

export function create(url, payload = null) {
  return (dispatch, getState) => {
    dispatch(apiCreate({ url }));

    return axiosInstance
      .post(url, payload)
      .then(({ data: { entities, results } }) => {
        dispatch(apiCreateSuccess({ entities }));
        return results;
      })
      .catch((error) => {
        dispatch(apiCreateFailure({ error }));
        throw error;
      });
  };
}

export function update(url, payload = null) {
  return (dispatch, getState) => {
    dispatch(apiUpdate({ url }));

    return axiosInstance
      .put(url, payload)
      .then(({ data: { entities, results } }) => {
        dispatch(apiUpdateSuccess({ entities }));
        return results;
      })
      .catch((error) => {
        dispatch(apiUpdateFailure({ error }));
        throw error;
      });
  };
}

export function destroy(url, { entity } = {}) {
  return (dispatch, getState) => {
    dispatch(apiDestroy({ url }));

    return axiosInstance
      .delete(url)
      .then(() => dispatch(apiDestroySuccess({ entity })))
      .catch((error) => {
        dispatch(apiDestroyFailure({ error }));
        throw error;
      });
  };
}
