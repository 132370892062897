import React from "react";
import { createConsumer } from "@rails/actioncable";

type PropTypes = {
  url: string;
  getUser(): void;
  getForm(params: { formId: string }): void;
};

export function Push({ url, getUser, getForm }: PropTypes) {
  React.useEffect(() => {
    const cable = createConsumer(url);

    cable.subscriptions.create("NotificationsChannel", {
      received({ type, id }) {
        switch (type) {
          case "user":
            return getUser();
          case "forms":
            return getForm({ formId: id });
          default:
            console.warn(`Unknown type: ${type}`);
        }
      },
    });
  }, [url, getUser, getForm]);

  return null;
}

export default Push;
