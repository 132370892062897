import React, { Component } from "react";
import PropTypes from "prop-types";

import Logo from "../../utilities/Logo";

import "./style.css";

class AppLoadingScreen extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    children: "Loading forms and mails...",
  };

  render() {
    return (
      <div className="app-loading-screen">
        <Logo className="app-loading-screen_logo" />
        <p className="app-loading-screen_text">{this.props.children}</p>
      </div>
    );
  }
}

export default AppLoadingScreen;
