import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter } from "../../utilities/Modal";

import { Button } from "../../utilities/Button";
import ExternalLink from "../../utilities/ExternalLink";

class ReloadError extends Component {
  static propTypes = {
    error: PropTypes.any,
    onClearErrors: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Modal title="Looks like something went wrong!">
        <ModalBody>
          <p>
            We track those errors automatically, but if the problem persists,
            feel free to contact us at{" "}
            <ExternalLink href="mailto:help@forwarder.cc">
              help@forwarder.cc
            </ExternalLink>
            .
          </p>
          <p>
            In the meantime, you can try refreshing this page or see{" "}
            <ExternalLink href="http://status.forwarder.cc">
              our status page
            </ExternalLink>
            .
          </p>
        </ModalBody>

        <ModalFooter>
          <Button color="error" onPress={this.props.onClearErrors} link>
            Ignore
          </Button>
          <div className="flex-spacer" />
          <Button onPress={() => window.location.reload()}>Reload app</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ReloadError;
