import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
} from "../constants/ActionTypes";

import { fetch, update } from "./APIActions";

const getUserAction = () => ({ type: GET_USER });
const getUserSuccessAction = ({ users }) => ({ type: GET_USER_SUCCESS, users });
const getUserFailureAction = ({ error }) => ({ type: GET_USER_FAILURE, error });

export function getUser() {
  return (dispatch) => {
    dispatch(getUserAction());

    return dispatch(fetch("/user"))
      .then(({ users }) => dispatch(getUserSuccessAction({ users })))
      .catch((error) => dispatch(getUserFailureAction({ error })));
  };
}

const updateUserAction = () => ({ type: UPDATE_USER });
const updateUserSuccessAction = ({ users }) => ({
  type: UPDATE_USER_SUCCESS,
  users,
});
const updateUserFailureAction = ({ error }) => ({
  type: UPDATE_USER_FAILURE,
  error,
});

export function updateUser({ user }) {
  return (dispatch) => {
    dispatch(updateUserAction({ user }));

    return dispatch(update("/user", { user }))
      .then(({ users }) => dispatch(updateUserSuccessAction({ users })))
      .catch((error) => dispatch(updateUserFailureAction({ error })));
  };
}
