import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { NEW_PURCHASE_ROUTE } from "../../constants/Routes";
import { Button } from "../utilities/Button";
import Logo from "../utilities/Logo";
import Icon from "../utilities/Icon";
import UserRecord from "../../records/UserRecord";
import { Item, Section } from "react-stately";
import { MenuButton } from "../utilities/Menu/MenuButton";

export function AppHeader({
  user,
  onSignOut,
}: {
  user: UserRecord;
  onSignOut: () => void;
}) {
  const navigate = useNavigate();

  const onMenuAction = React.useCallback(
    (key: React.Key) => {
      switch (key) {
        case "settings":
          return navigate("/user/settings");
        case "credits":
          return navigate("/user/credits");
        case "help":
          return navigate("/help");
        case "sign-out":
          return onSignOut();
      }
    },
    [navigate, onSignOut]
  );

  return (
    <div className="app-header">
      <div className="app-wrapper app-wrapper--header">
        <div className="app-header_group">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <div className="app-header_group">
          {!user.confirmed && (
            <Button color="error" link>
              Confirm your email
            </Button>
          )}
          <MenuButton
            label="Your account"
            onAction={onMenuAction}
            disabledKeys={["help", "signout"]}
            align="end"
            link
          >
            <Section>
              <Item key="settings" textValue="Account settings">
                <Icon id="account" size="small" />
                <span>Account settings</span>
              </Item>
              <Item key="credits" textValue="Credits">
                <Icon id="credits" size="small" />
                <span>Credits</span>
              </Item>
              <Item key="help" textValue="Help & Frequently Asked Questions">
                <Icon id="help" size="small" />
                <span>Help / FAQ</span>
              </Item>
            </Section>
            <Section>
              <Item key="sign-out" textValue="Sign out">
                <Icon id="blank" size="small" />
                <span>Sign out</span>
              </Item>
            </Section>
          </MenuButton>

          <Button to={NEW_PURCHASE_ROUTE} color="primary-alt">
            Buy Credits
          </Button>
        </div>
      </div>
    </div>
  );
}
