import React from "react";
import { Node } from "@react-types/shared";
import { TreeState } from "react-stately";
import { useMenuSection, useSeparator } from "react-aria";
import { MenuItem } from "./MenuItem";

type MenuSectionProps<T> = {
  section: Node<T>;
  state: TreeState<T>;
  onAction?: (key: React.Key) => void;
  onClose: () => void;
};

export function MenuSection<T>({
  section,
  state,
  onAction,
  onClose,
}: MenuSectionProps<T>) {
  let { itemProps, groupProps } = useMenuSection({
    heading: section.rendered,
    "aria-label": section["aria-label"],
  });

  let { separatorProps } = useSeparator({
    elementType: "li",
  });

  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li {...separatorProps} className="menu-item divider" />
      )}
      <li {...itemProps}>
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <MenuItem
              key={node.key}
              item={node}
              state={state}
              onAction={onAction}
              onClose={onClose}
            />
          ))}
        </ul>
      </li>
    </>
  );
}
