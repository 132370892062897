import {
  INIT,
  INIT_SUCCESS,
  UPDATE_FORM_CLOSED,
  CLEAR_ERRORS,
} from "../constants/ActionTypes";

import { getUser } from "./UserActions";

const initAction = () => ({ type: INIT });
const initSuccessAction = () => ({ type: INIT_SUCCESS });

export function init() {
  return (dispatch) => {
    dispatch(initAction());

    return dispatch(getUser())
      .catch(() => ({
        /* Sometimes things failed buddy */
      }))
      .then(() => dispatch(initSuccessAction()));
  };
}

const updateFormClosedAction = () => ({ type: UPDATE_FORM_CLOSED });

export function updateFormClosed() {
  return (dispatch) => {
    return dispatch(updateFormClosedAction());
  };
}

const clearErrorsAction = () => ({ type: CLEAR_ERRORS });

export function clearErrors() {
  return (dispatch) => {
    dispatch(clearErrorsAction());
  };
}
