import React from "react";
import classNames from "classnames";
// import { Modal as ReactOverlayModal } from "react-overlays";

import {
  FocusScope,
  Overlay,
  useDialog,
  useModalOverlay,
  useOverlayTrigger,
} from "react-aria";
import { useOverlayTriggerState } from "react-stately";

import "./style.css";

type ModalOverlayPropTypes = {
  isOpen: boolean;
  children: React.ReactElement;
};

export function ModalOverlay({
  isOpen = true,
  children,
}: ModalOverlayPropTypes) {
  const ref = React.useRef(null);
  const state = useOverlayTriggerState({ isOpen });
  let { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "dialog" },
    state,
    ref
  );
  const { modalProps, underlayProps } = useModalOverlay({}, state, ref);

  console.log({ triggerProps, overlayProps });

  return (
    <Overlay>
      <div className="modal" {...underlayProps} {...modalProps} ref={ref}>
        {React.cloneElement(children, overlayProps)}
      </div>
    </Overlay>
  );
}

type ModalPropTypes = {
  isOpen?: boolean;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
};

export function Modal({
  isOpen = true,
  title,
  onClose,
  children,
  ...props
}: ModalPropTypes) {
  const ref = React.useRef(null);
  const { dialogProps, titleProps } = useDialog({}, ref);

  return (
    <ModalOverlay isOpen>
      <FocusScope contain restoreFocus autoFocus>
        <div className="modal-dialog" {...dialogProps} ref={ref}>
          <h3 className="modal-dialog-header" {...titleProps}>
            {title}
          </h3>

          {children}
        </div>
      </FocusScope>
    </ModalOverlay>
  );

  // return (
  //   <ReactOverlayModal show={show} onHide={onClose} className="modal">
  //     <>
  //       <div className="modal-backdrop" onClick={onClose} />
  //       <div className="modal-dialog">{children}</div>
  //     </>
  //   </ReactOverlayModal>
  // );
}

type ModalBodyPropTypes = {
  className?: string;
  children: React.ReactNode;
};

export function ModalBody({ className, children }: ModalBodyPropTypes) {
  return (
    <div className={classNames("modal-dialog-body", className)}>
      <div className="modal-dialog-body-inner">{children}</div>
    </div>
  );
}

type ModalFooterPropTypes = {
  className?: string;
  children: React.ReactNode;
};

export function ModalFooter({ className, children }: ModalFooterPropTypes) {
  return (
    <div className={classNames("modal-dialog-footer", className)}>
      {children}
    </div>
  );
}
