import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import type { AriaButtonProps } from "@react-types/button";
import { useButton, useFocusRing } from "react-aria";

import "./style.css";
import { PressEvent } from "@react-types/shared";

type ButtonPropTypes = AriaButtonProps<"button"> & {
  color?: "default" | "primary" | "primary-alt" | "error" | "error-alt";
  link?: boolean;
  icon?: boolean;
  disabled?: boolean;
  onPress?: (e: PressEvent) => void;
  to?: string;
  className?: string;
  isPressed?: boolean;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonPropTypes>(
  function Button(
    {
      color = "default",
      link = false,
      icon = false,
      to,
      className,
      children,
      ...props
    },
    ref
  ) {
    const navigate = useNavigate();

    const onPress = React.useCallback(
      (e: PressEvent) => (to ? navigate(to) : props.onPress?.(e)),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [navigate, to, props.onPress]
    );

    let { buttonProps } = useButton(
      { ...props, onPress, isDisabled: props.disabled },
      ref as React.RefObject<HTMLButtonElement>
    );
    let { focusProps, isFocusVisible, isFocused } = useFocusRing();

    const buttonClassName = classNames(
      "button",
      className,
      `button--${color}`,
      {
        "button--link": link,
        "button--icon": icon,
        "button--focused": isFocused,
        "button--focus-visible": isFocusVisible,
        disabled: props.disabled,
      }
    );

    return (
      <button
        ref={ref}
        className={buttonClassName}
        {...buttonProps}
        {...focusProps}
      >
        {children}
      </button>
    );
  }
);
