import Immutable, { Record } from "immutable";
import uniqueId from "lodash/uniqueId";

import { getEntities } from "../utils/records";

import SubmissionRecord from "../records/SubmissionRecord";

const defaultAttributes = {
  id: null,
  title: "",
  token: "",
  destinationEmail: "",
  successUrl: "",
  errorUrl: "",
  captchaService: "",
  captchaSecretKey: "",
  pushChannel: "",
  unreadCount: 0,
  blockedCount: 0,
  submissions: [],
};

class FormRecord extends (new Record(defaultAttributes)) {
  constructor(attributes = new Immutable.Map()) {
    super({
      id: uniqueId("FormRecord_"),
      ...attributes.toJS(),
    });
  }

  getSubmissions() {
    return getEntities("submissions", this.submissions).map(
      (submission) => new SubmissionRecord(submission)
    );
  }

  hasBlockedSubmissions() {
    return this.blockedCount > 0;
  }
}

export default FormRecord;
