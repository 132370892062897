import { connect } from "react-redux";
import { WS_URL } from "../constants/Api";
import { getUser } from "../actions/UserActions";
import { getForm } from "../actions/FormsActions";
import Push from "../components/Push";

function mapStateToProps({ user }, props) {
  return {
    url: WS_URL,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    getUser: () => dispatch(getUser()),
    getForm: ({ formId }) => dispatch(getForm({ formId })),
    // getPurchases: () => dispatch(getPurchases()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Push);
