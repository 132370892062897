import { generatePath } from "react-router-dom";
import {
  FORMS_ROUTE,
  FORM_ROUTE,
  FORM_SUBMISSION_ROUTE,
  FORM_SETTINGS_ROUTE,
  FORM_SUBMISSION_SETTINGS_ROUTE,
  FORM_DELETE_ROUTE,
  FORM_SUBMISSION_DELETE_ROUTE,
  NEW_FORM_ROUTE,
  FORM_NEW_FORM_ROUTE,
  FORM_SUBMISSION_NEW_FORM_ROUTE,
} from "../constants/Routes";

export function buildFormURL({ formId }: { formId: string }) {
  return generatePath(FORM_ROUTE, { formId });
}

export function buildFormSubmissionURL({
  formId,
  submissionId,
}: {
  formId: string;
  submissionId: string;
}) {
  return generatePath(FORM_SUBMISSION_ROUTE, { formId, submissionId });
}

export function buildFormSettingsURL({
  formId,
  submissionId,
}: {
  formId: string;
  submissionId?: string;
}) {
  if (submissionId) {
    return generatePath(FORM_SUBMISSION_SETTINGS_ROUTE, {
      formId,
      submissionId,
    });
  }
  return generatePath(FORM_SETTINGS_ROUTE, { formId });
}

export function buildFormSettingsCloseURL({
  formId,
  submissionId,
}: {
  formId: string;
  submissionId?: string;
}) {
  if (submissionId) {
    return generatePath(FORM_SUBMISSION_ROUTE, { formId, submissionId });
  }
  return generatePath(FORM_ROUTE, { formId });
}

export function buildFormDeleteURL({
  formId,
  submissionId,
}: {
  formId: string;
  submissionId?: string;
}) {
  if (submissionId) {
    return generatePath(FORM_SUBMISSION_DELETE_ROUTE, { formId, submissionId });
  }
  return generatePath(FORM_DELETE_ROUTE, { formId });
}

export function buildFormDeleteCloseURL({
  formId,
  submissionId,
}: {
  formId: string;
  submissionId?: string;
}) {
  if (submissionId) {
    return generatePath(FORM_SUBMISSION_SETTINGS_ROUTE, {
      formId,
      submissionId,
    });
  }
  return generatePath(FORM_SETTINGS_ROUTE, { formId });
}

export function buildNewFormURL({
  formId,
  submissionId,
}: {
  formId?: string;
  submissionId?: string;
}) {
  if (submissionId && formId) {
    return generatePath(FORM_SUBMISSION_NEW_FORM_ROUTE, {
      formId,
      submissionId,
    });
  } else if (formId) {
    return generatePath(FORM_NEW_FORM_ROUTE, { formId });
  }
  return generatePath(NEW_FORM_ROUTE);
}

export function buildNewFormCloseURL({
  formId,
  submissionId,
}: {
  formId?: string;
  submissionId?: string;
}) {
  if (submissionId && formId) {
    return generatePath(FORM_SUBMISSION_ROUTE, { formId, submissionId });
  } else if (formId) {
    return generatePath(FORM_ROUTE, { formId });
  }
  return generatePath(FORMS_ROUTE);
}
