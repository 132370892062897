import React, { Suspense } from "react";
import { Helmet } from "react-helmet-async";

import ApplicationStateRecord from "../../records/ApplicationStateRecord";
import { AppError } from "./Error";
import { AppHeader } from "./Header";
import { AppContent } from "./Content";
import AppLoadingScreen from "./LoadingScreen";

import "./style.css";
import { useLocation } from "react-router-dom";
import UserRecord from "../../records/UserRecord";

const AuthenticateContainer = React.lazy(
  () => import("../../containers/AuthenticateContainer")
);

type PropTypes = {
  application: typeof ApplicationStateRecord;
  user?: UserRecord;
  init: () => void;
  getForms: () => void;
  signOut: () => void;
  clearErrors: () => void;
};

function App({
  init,
  application,
  user,
  getForms,
  signOut,
  clearErrors,
}: PropTypes) {
  const location = useLocation();
  const isUserLoaded = !!user;

  React.useEffect(() => {
    if (!application.initialized) {
      init();
    }
  }, [application.initialized, init]);

  React.useEffect(() => {
    clearErrors();
  }, [location, clearErrors]);

  const content = React.useMemo(() => {
    if (!application.initialized) {
      return <AppLoadingScreen />;
    } else if (!isUserLoaded) {
      return <AuthenticateContainer key="auth" />;
    }

    return [
      <AppHeader key="header" user={user} onSignOut={signOut} />,
      <AppContent key="content" user={user} getForms={getForms} />,
    ];
  }, [isUserLoaded, application.initialized, user, signOut, getForms]);

  return (
    <div className="app">
      <Helmet>
        <title>Forwarder.cc</title>
      </Helmet>
      <AppError error={application.error} onClearErrors={clearErrors} />
      <Suspense fallback={<AppLoadingScreen />}>{content}</Suspense>
    </div>
  );
}

export default App;
