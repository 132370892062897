import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router } from "react-router-dom";

import AppContainer from "./containers/AppContainer";
import store from "./store";

import "./variables.css";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <HelmetProvider>
        <Router basename={process.env.PUBLIC_URL || "/app"}>
          <AppContainer />
        </Router>
      </HelmetProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
