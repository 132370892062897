import React from "react";
import classNames from "classnames";

import "./style.css";

/* eslint-disable import/no-webpack-loader-syntax */
const ICONS = {
  small: {
    account: require("!raw-loader!./icons/20/account.svg").default,
    checkbox_fill: require("!raw-loader!./icons/20/checkbox_fill.svg").default,
    checkbox: require("!raw-loader!./icons/20/checkbox.svg").default,
    close: require("!raw-loader!./icons/20/close.svg").default,
    credits: require("!raw-loader!./icons/20/credits.svg").default,
    help: require("!raw-loader!./icons/20/help.svg").default,
    more: require("!raw-loader!./icons/20/more.svg").default,
    report: require("!raw-loader!./icons/20/report.svg").default,
    settings: require("!raw-loader!./icons/20/settings.svg").default,
    trash: require("!raw-loader!./icons/20/garbage.svg").default,
    blank: "",
  },
  base: {
    arrow_left: require("!raw-loader!./icons/24/arrow_left.svg").default,
    arrow_right: require("!raw-loader!./icons/24/arrow_right.svg").default,
    chevron_down: require("!raw-loader!./icons/24/chevron_down.svg").default,
    "not-visible": require("!raw-loader!./icons/24/not-visible.svg").default,
    radio_fill: require("!raw-loader!./icons/24/radio_fill.svg").default,
    radio: require("!raw-loader!./icons/24/radio.svg").default,
    visible: require("!raw-loader!./icons/24/visible.svg").default,
    // Migrated
    blank: "",
  },
};

type Icons = typeof ICONS;
type IconSize = keyof Icons;
export type SmallIcons = keyof Icons["small"];

export function Icon<K extends IconSize>({
  id,
  size = "base" as K,
  iconStyle = "default",
  className = "",
}: {
  id: keyof Icons[K];
  size: K;
  iconStyle?: "default" | "primary" | "error";
  className?: string;
}) {
  return (
    <span
      className={classNames(
        "icon",
        `icon--id-${id as string}`,
        `icon--size-${size as string}`,
        `icon--style-${iconStyle}`,
        className
      )}
      dangerouslySetInnerHTML={{ __html: ICONS[size][id] as string }}
    />
  );
}

export default Icon;
