import React from "react";
import { Node } from "@react-types/shared";
import { TreeState } from "react-stately";
import { useMenuItem } from "react-aria";

type MenuItemProps<T> = {
  item: Node<T>;
  state: TreeState<T>;
  onAction?: (key: React.Key) => void;
  onClose: () => void;
};

export function MenuItem<T>({
  item,
  state,
  onAction,
  onClose,
}: MenuItemProps<T>) {
  // Get props for the menu item element
  let ref = React.useRef(null);
  let { menuItemProps } = useMenuItem(
    {
      key: item.key,
      onAction,
      onClose,
    },
    state,
    ref
  );

  // Handle focus events so we can apply highlighted
  // style to the focused menu item
  // let isFocused = state.selectionManager.focusedKey === item.key;
  // let focusBg = item.key === "delete" ? "bg-red-500" : "bg-blue-500";
  // let focus = isFocused ? `${focusBg} text-white` : "text-gray-900";

  return (
    <li {...menuItemProps} ref={ref} className={`menu-item`}>
      {item.rendered}
    </li>
  );
}
