import React from "react";
import classNames from "classnames";

type PropTypes = {
  size?: number;
  withTitle?: boolean;
  className?: string;
};

export function Logo({
  size = 36,
  withTitle = false,
  className = "",
}: PropTypes) {
  return (
    <div
      className={classNames("logo", className)}
      style={{
        fontSize: `${size * 0.5}px`,
        fontWeight: 900,
        lineHeight: `${size}px`,
      }}
    >
      <svg width={size} height={size} viewBox="0 0 36 36">
        <g fill="none" fillRule="nonzero">
          <path
            fill="#0950CB"
            d="M7 11a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1m-2 5a1 1 0 0 1-1 1H1a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1m-2 5a1 1 0 0 1-1 1H1a1 1 0 1 1 0-2h1a1 1 0 0 1 1 1"
          />
          <path
            fill="#9EBDF2"
            d="M32.301 24c-.626 2.209-2.925 4-5.134 4h-20c-2.209 0-3.492-1.791-2.866-4l3.398-12c.626-2.209 2.924-4 5.133-4h20c2.209 0 3.493 1.791 2.867 4l-3.398 12Z"
          />
          <path
            fill="#0950CB"
            d="M17.336 16.636 4.384 25.949c-.034.028-.054.063-.085.091a2.66 2.66 0 0 0 .992 1.384c.035-.023.073-.033.107-.06L18.35 18.05c.501-.391.681-1.023.401-1.414-.281-.391-.913-.391-1.415 0m13.81 9.404c-.015-.028-.016-.063-.034-.09l-7.674-9.314c-.281-.391-.913-.391-1.416 0-.501.391-.68 1.023-.4 1.414l7.676 9.314c.018.026.051.037.072.06a6.04 6.04 0 0 0 1.776-1.384"
          />
          <path
            fill="#0950CB"
            d="M35.699 12c.626-2.209-.658-4-2.867-4h-20c-2.209 0-4.507 1.791-5.133 4l-.021.074 8.806 8.452c1.631 1.584 3.788 1.475 5.725.371l13.227-7.964.263-.933Z"
          />
          <path
            fill="#CEDCF3"
            d="M32.832 8h-20c-1.578 0-3.189.921-4.217 2.248l9.217 8.794c.749.719 2.434.729 3.656 0l14.294-8.768C35.516 8.933 34.42 8 32.832 8Z"
          />
        </g>
      </svg>
      {withTitle && <span className="logo-title">Forwarder.cc</span>}
    </div>
  );
}

export default Logo;
