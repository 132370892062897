import React from "react";
import {
  useParams,
  useNavigate,
  Params,
  NavigateFunction,
} from "react-router-dom";

type PropTypes = {};
type ExtendedPropTypes = PropTypes & {
  params: Params<string>;
  navigate: NavigateFunction;
};

/**
 * @deprecated Use react-router-dom hooks instead
 */
export function withDeprecatedRouter<
  T extends ExtendedPropTypes = ExtendedPropTypes
>(Component: React.ComponentType<T>) {
  const ComponentWithRouter = (props: Omit<T, keyof ExtendedPropTypes>) => {
    const params = useParams();
    const navigate = useNavigate();
    return <Component {...(props as T)} params={params} navigate={navigate} />;
  };

  ComponentWithRouter.displayName = `withDeprecatedRouter(${Component.displayName})`;

  return ComponentWithRouter;
}
