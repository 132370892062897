import BaseError from "./BaseError";

class NetworkError extends BaseError {
  constructor(attributes) {
    super({
      ...attributes,
      recoverable: false,
    });
  }
}

export default NetworkError;
