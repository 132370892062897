import React, { Component } from "react";
import PropTypes from "prop-types";

import BaseError from "../../../records/errors/BaseError";
import NetworkError from "../../../records/errors/NetworkError";
import ServerError from "../../../records/errors/ServerError";
import RequestError from "../../../records/errors/RequestError";

import ReloadError from "./ReloadError";

export class AppError extends Component {
  static propTypes = {
    error: PropTypes.instanceOf(BaseError),
    onClearErrors: PropTypes.func.isRequired,
  };

  render() {
    const { error, onClearErrors } = this.props;
    if (!error) {
      return false;
    }

    switch (error.constructor) {
      case NetworkError:
      case ServerError:
        return <ReloadError error={error} onClearErrors={onClearErrors} />;
      case RequestError:
      default:
        return false;
    }
  }
}
