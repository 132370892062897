import React from "react";
import type { AriaMenuProps, MenuTriggerProps } from "@react-types/menu";
import { useMenuTriggerState } from "react-stately";
import { useMenuTrigger } from "react-aria";
import { Button } from "../Button";
import { Popover } from "../Popover";
import { Menu } from "./Menu";
import { Icon, SmallIcons } from "../Icon";

type MenuButtonProps<T extends object> = AriaMenuProps<T> &
  MenuTriggerProps & {
    label?: string;
    icon?: SmallIcons;
    link?: boolean;
  };

export function MenuButton<T extends object>({
  link,
  ...props
}: MenuButtonProps<T>) {
  const isIcon = props.icon;
  // Create state based on the incoming props
  let state = useMenuTriggerState({ ...props });

  // Get props for the menu trigger and menu elements
  let ref = React.useRef(null);
  let { menuTriggerProps, menuProps } = useMenuTrigger<T>({}, state, ref);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {isIcon ? (
        <Button
          {...menuTriggerProps}
          isPressed={state.isOpen}
          ref={ref}
          aria-label={props.label}
          icon
        >
          <Icon id={props.icon!} size="small" />
        </Button>
      ) : (
        <Button
          {...menuTriggerProps}
          link={link}
          isPressed={state.isOpen}
          ref={ref}
        >
          {props.label}
        </Button>
      )}
      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement="bottom start">
          <Menu
            {...menuProps}
            {...props}
            autoFocus={state.focusStrategy || true}
            onClose={() => state.close()}
          />
        </Popover>
      )}
    </div>
  );
}
