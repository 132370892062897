import { connect } from "react-redux";

import { getEntity, presentEntity } from "../utils/records";
import { init, clearErrors } from "../actions/ApplicationActions";
import { getForms } from "../actions/FormsActions";
import { signOut } from "../actions/AuthenticationActions";
import UserRecord from "../records/UserRecord";
import App from "../components/App";

function mapStateToProps({ application }) {
  const user = presentEntity(getEntity("users", application.user), UserRecord);
  return { application, user };
}

function mapDispatchToProps(dispatch) {
  return {
    init: () => dispatch(init()),
    getForms: () => dispatch(getForms()),
    signOut: () => dispatch(signOut()),
    clearErrors: () => dispatch(clearErrors()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
