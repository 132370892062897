import Immutable from "immutable";

import { DELETE_SUBMISSION_SUCCESS } from "../constants/ActionTypes";

export default function undoReducer(undoStack = new Immutable.Stack(), action) {
  switch (action.type) {
    case DELETE_SUBMISSION_SUCCESS: {
      return undoStack.push({
        id: action.submissionId,
        type: "submissions",
        dependsOf: [action.formId],
      });
    }

    default:
      return undoStack;
  }
}
