import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
} from "../constants/ActionTypes";
import {
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
} from "../constants/ActionTypes";
import {
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
} from "../constants/ActionTypes";
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from "../constants/ActionTypes";
import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "../constants/ActionTypes";

import { create, destroy } from "./APIActions";

const signInAction = () => ({ type: SIGN_IN });
const signInSuccessAction = ({ users }) => ({ type: SIGN_IN_SUCCESS, users });
const signInFailureAction = ({ error }) => ({ type: SIGN_IN_FAILURE, error });

export function signIn({ user }) {
  return (dispatch) => {
    dispatch(signInAction());

    return dispatch(create("/sessions", user))
      .then(({ users }) => dispatch(signInSuccessAction({ users })))
      .catch((error) => dispatch(signInFailureAction({ error })));
  };
}

const signUpAction = () => ({ type: SIGN_UP });
const signUpSuccessAction = ({ users }) => ({ type: SIGN_UP_SUCCESS, users });
const signUpFailureAction = ({ error }) => ({ type: SIGN_UP_FAILURE, error });

export function signUp({ user }) {
  return (dispatch) => {
    dispatch(signUpAction());

    return dispatch(create("/users", { user }))
      .then(({ users }) => dispatch(signUpSuccessAction({ users })))
      .catch((error) => dispatch(signUpFailureAction({ error })));
  };
}

const signOutAction = () => ({ type: SIGN_OUT });
const signOutSuccessAction = () => ({ type: SIGN_OUT_SUCCESS });
const signOutFailureAction = ({ error }) => ({ type: SIGN_OUT_FAILURE, error });

export function signOut() {
  return (dispatch) => {
    dispatch(signOutAction());

    return dispatch(destroy("/sessions"))
      .then(() => dispatch(signOutSuccessAction()))
      .catch((error) => {
        dispatch(signOutFailureAction({ error }));
        throw error;
      });
  };
}

const forgotPasswordAction = () => ({ type: FORGOT_PASSWORD });
const forgotPasswordSuccessAction = ({ users }) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  users,
});
const forgotPasswordFailureAction = ({ error }) => ({
  type: FORGOT_PASSWORD_FAILURE,
  error,
});

export function forgotPassword({ email }) {
  return (dispatch) => {
    dispatch(forgotPasswordAction());

    return dispatch(create("/user/password/forgot", { email }))
      .then(({ users }) => dispatch(forgotPasswordSuccessAction({ users })))
      .catch((error) => dispatch(forgotPasswordFailureAction({ error })));
  };
}

const resetPasswordAction = () => ({ type: RESET_PASSWORD });
const resetPasswordSuccessAction = ({ users }) => ({
  type: RESET_PASSWORD_SUCCESS,
  users,
});
const resetPasswordFailureAction = ({ error }) => ({
  type: RESET_PASSWORD_FAILURE,
  error,
});

export function resetPassword({ token, password }) {
  return (dispatch) => {
    dispatch(resetPasswordAction());

    return dispatch(create("/user/password/reset", { token, password }))
      .then(({ users }) => dispatch(resetPasswordSuccessAction({ users })))
      .catch((error) => dispatch(resetPasswordFailureAction({ error })));
  };
}
