import { Record } from "immutable";

const defaultAttributes = {
  type: "String",
  email: null,
  unconfirmedEmail: null,
  credits: 0,
  organization: "",
  fullName: "",
  address: "",
  city: "",
  zip: "",
  country: "",
  state: "",
  confirmed: false,
};

class UserRecord extends (new Record(defaultAttributes)) {}

export default UserRecord;
