import BaseError from "./BaseError";

class RequestError extends BaseError {
  constructor(attributes) {
    super({
      ...attributes,
      recoverable: true,
    });
  }
}

export default RequestError;
