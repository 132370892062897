import React from "react";
import classNames from "classnames";

import "./style.css";

type PropTypes = {
  type?: "default" | "info" | "warning" | "error";
  children: React.ReactNode;
};

export function Banner({ type = "default", children }: PropTypes) {
  return (
    <div className={classNames("banner", `banner--${type}`)}>
      <div className="banner-content">{children}</div>
    </div>
  );
}
