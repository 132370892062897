import { Record } from "immutable";
import UserRecord from "../records/UserRecord";

const ApplicationSettingsStateRecord = new Record({
  submitting: false,
  errors: null,
});

const ApplicationFormStateRecord = new Record({
  submitting: false,
  errors: null,
});

export default new Record({
  initialized: false,
  user: new UserRecord(),
  settingsState: new ApplicationSettingsStateRecord(),
  formState: new ApplicationFormStateRecord(),
  error: null,
});
