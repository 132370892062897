import React, { Component } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";

import UserRecord from "../../records/UserRecord";
import { buildNewFormURL } from "../../utils/routes";
import { formatNumber } from "../../utils/numbers";
import FormListItem from "./FormListItem";
import { Button } from "../utilities/Button";

import "./style.css";

function CreditsCounter({ count }) {
  if (count === 1) {
    return `${formatNumber(count)} credit left`;
  } else {
    return `${formatNumber(count)} credits left`;
  }
}

class Sidebar extends Component {
  static propTypes = {
    params: PropTypes.shape({
      formId: PropTypes.string,
      submissionId: PropTypes.string,
    }),
    user: PropTypes.instanceOf(UserRecord),
    forms: PropTypes.instanceOf(Immutable.List),
  };

  static defaultProps = {
    forms: new Immutable.List(),
  };

  render() {
    const {
      params,
      user: { credits },
    } = this.props;

    return (
      <div className="form-list">
        <div className="form-list_header">
          <h2 className="form-list_header_title">Forms</h2>
          <div className="form-list_header_counter">
            <CreditsCounter count={credits} />
          </div>
        </div>

        {this.props.forms.map((form) => (
          <FormListItem key={form.id} form={form} />
        ))}

        <hr />

        <Button
          className="form-list_new-form"
          to={buildNewFormURL(params)}
          link
        >
          + Add a new form
        </Button>
      </div>
    );
  }
}

export default Sidebar;
