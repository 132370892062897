import Immutable from "immutable";

import {
  CLEAR_ERRORS,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "../constants/ActionTypes";

export default function applicationReducer(
  authentication = new Immutable.Map(),
  action
) {
  switch (action.type) {
    case SIGN_IN:
    case SIGN_UP:
    case FORGOT_PASSWORD:
    case RESET_PASSWORD:
      return authentication
        .set("submitting", true)
        .set("errors", new Immutable.Map());

    case SIGN_IN_SUCCESS:
    case SIGN_UP_SUCCESS:
    case FORGOT_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return authentication.set("submitting", false);

    case SIGN_IN_FAILURE:
      if (action.error.response) {
        return authentication
          .set("submitting", false)
          .set("errors", Immutable.fromJS({ password: ["unknown"] }));
      }
      return authentication.set("submitting", false);

    case SIGN_UP_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
      if (action.error.response) {
        return authentication
          .set("submitting", false)
          .set("errors", Immutable.fromJS(action.error.response.data.errors));
      }
      return authentication.set("submitting", false);

    case CLEAR_ERRORS:
      return authentication.set("errors", null);

    default:
      return authentication;
  }
}
