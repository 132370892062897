import { combineReducers } from "redux";

import ApplicationStateRecord from "../records/ApplicationStateRecord";
import EntitiesStateRecord from "../records/EntitiesStateRecord";
import { SIGN_OUT_SUCCESS } from "../constants/ActionTypes";

import applicationReducer from "./applicationReducer";
import authenticationReducer from "./authenticationReducer";
import entitiesReducer from "./entitiesReducer";
import undoReducer from "./undoReducer";

export const initialState = {
  application: new ApplicationStateRecord(),
  entities: new EntitiesStateRecord(),
};

const appReducer = combineReducers({
  user: (state = {}) => state,
  application: applicationReducer,
  authentication: authenticationReducer,
  entities: entitiesReducer,
  undoStack: undoReducer,
});

export default function reducers(state, action) {
  if (action.type === SIGN_OUT_SUCCESS) {
    window.location = "/";
    return appReducer(initialState, action);
  }

  return appReducer(state, action);
}
