import { connect } from "react-redux";
import { getForms } from "../actions/FormsActions";

import { getEntity, presentEntity } from "../utils/records";
import UserRecord from "../records/UserRecord";
import FormRecord from "../records/FormRecord";
import Sidebar from "../components/Sidebar";
import { withDeprecatedRouter } from "../utils/withDeprecatedRouter";

function mapStateToProps({ application, ui, entities, forms }, { params }) {
  const user = presentEntity(getEntity("users", application.user), UserRecord);

  return {
    params,
    user,
    forms: entities
      .get("forms")
      .map((form) => new FormRecord(form))
      .sortBy((form) => form.title)
      .toList(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getForms: () => dispatch(getForms()),
  };
}

export default withDeprecatedRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
