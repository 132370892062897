import Immutable, { Record } from "immutable";
import uniqueId from "lodash/uniqueId";

const defaultAttributes = {
  id: null,
  url: null,
  name: null,
  size: null,
  contentType: null,
};

class FileRecord extends (new Record(defaultAttributes)) {
  constructor(attributes = new Immutable.Map()) {
    super({
      id: uniqueId("FileRecord_"),
      ...attributes.toJS(),
    });
  }
}

export default FileRecord;
