export const FORMS_ROUTE = "/forms";
export const FORM_ROUTE = "/forms/:formId";

export const FORM_SUBMISSIONS_ROUTE = "/forms/:formId/submissions";
export const FORM_SUBMISSION_ROUTE = "/forms/:formId/submissions/:submissionId";

export const FORM_SETTINGS_ROUTE = "/forms/:formId/settings";
export const FORM_SUBMISSION_SETTINGS_ROUTE =
  "/forms/:formId/submissions/:submissionId/settings";

export const FORM_DELETE_ROUTE = "/forms/:formId/delete";
export const FORM_SUBMISSION_DELETE_ROUTE =
  "/forms/:formId/submissions/:submissionId/delete";

export const NEW_FORM_ROUTE = "/forms/new";
export const FORM_NEW_FORM_ROUTE = "/forms/:formId/new";
export const FORM_SUBMISSION_NEW_FORM_ROUTE =
  "/forms/:formId/submissions/:submissionId/new";

export const SETTINGS_ROUTE = "/user/settings";
export const PURCHASES_ROUTE = "/user/credits";
export const NEW_PURCHASE_ROUTE = "/user/credits/buy";
