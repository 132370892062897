import {
  isObject,
  groupBy,
  mapKeys,
  mapValues,
  camelCase,
  snakeCase,
  transform,
} from "lodash";

type ApiRef = {
  id: string;
  type: string;
};

type ApiEntity = ApiRef & {
  attributes: Record<string, unknown>;
  relationships: Record<string, { data: ApiRef }>;
};

type NormalizedApiEntity = ApiRef & Record<string, unknown>;

type NormalizableObject = {
  data: ApiEntity | ApiEntity[];
  included: ApiEntity[];
  errors: Record<string, { error: string }[]>;
};

function normalizeEntity({ id, type, attributes, relationships }: ApiEntity) {
  const normalized: NormalizedApiEntity = {
    id,
    type,
    ...mapKeys(attributes, (value, key) => camelCase(key)),
  };

  Object.keys(relationships || []).forEach((key) => {
    const value = relationships[key];
    if (Array.isArray(value.data)) {
      normalized[key] = value.data.map((item) => item.id);
    } else if (isObject(value.data)) {
      normalized[key] = value.data.id;
    }
  });

  return normalized;
}

function normalizeErrors(errors: NormalizableObject["errors"]) {
  return Object.entries(errors).reduce(
    (memo, [attribute, attributeErrors]) => ({
      [camelCase(attribute)]: attributeErrors.map(({ error }) => error),
      ...memo,
    }),
    {} as Record<string, string[]>
  );
}

function isNormalizable(object: any): object is NormalizableObject {
  return isObject(object) && ("data" in object || "errors" in object);
}

export function normalizeResponse(rawData: string | Record<string, unknown>) {
  if (typeof rawData === "string") {
    if (rawData === "") {
      return {};
    }
    rawData = JSON.parse(rawData);
  }

  if (!isNormalizable(rawData)) {
    return rawData || {};
  }

  const { data = {}, included = [], errors = {} } = rawData;
  let allEntities = (Array.isArray(data) ? data : [data])
    .map(normalizeEntity)
    .concat((included || []).map(normalizeEntity));

  const entities = mapValues(
    groupBy(allEntities, (value) => value.type),
    (value) =>
      value.reduce(
        (result, entity) => Object.assign({}, result, { [entity.id]: entity }),
        {} as Record<string, NormalizedApiEntity>
      )
  );

  const results = mapValues(entities, Object.keys);
  return { results, entities, errors: normalizeErrors(errors) };
}

function snakeCaseObject(obj: Record<string, unknown>) {
  return transform(
    obj,
    (result: Record<string, unknown>, value: unknown, key: string, target) => {
      const camelKey = Array.isArray(target) ? key : snakeCase(key);
      result[camelKey] = isObject(value)
        ? snakeCaseObject(value as Record<string, unknown>)
        : value;
    }
  );
}

export function normalizeRequest(object: Record<string, unknown>) {
  return snakeCaseObject(object);
}
