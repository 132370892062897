import Immutable, { Record } from "immutable";
import uniqueId from "lodash/uniqueId";
import mapKeys from "lodash/mapKeys";
import camelCase from "lodash/camelCase";
import moment from "moment-timezone";

import { getEntities } from "../utils/records";

import FieldRecord from "../records/FieldRecord";
import FileRecord from "../records/FileRecord";

const defaultAttributes = {
  id: null,
  formId: null,
  files: [],

  avatar: "",
  data: null,

  title: "",
  name: "",
  email: "",
  source: "",
  description: "",
  read: false,

  createdAt: null,
};

class SubmissionRecord extends (new Record(defaultAttributes)) {
  constructor(attributes = new Immutable.Map()) {
    super({
      id: uniqueId("SubmissionRecord_"),
      ...attributes.toJS(),
    });
  }

  getReceivedAt() {
    return moment(this.createdAt);
  }

  getFields() {
    return new Immutable.List(
      this.data.map(
        (field) =>
          new FieldRecord(mapKeys(field, (value, key) => camelCase(key)))
      )
    );
  }

  getFiles() {
    return getEntities("files", this.files).map((file) => new FileRecord(file));
  }
}

export default SubmissionRecord;
